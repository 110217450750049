import React from 'react';

import Collapsible from '../../library/collapsible/collapsible';
import Link from '../../library/link/link';
import Typography from '../../library/typography/typography';
import ProductAvailabilityCollapsible from './components/product-availability-collapsible/product-availability-collapsible';
import StoreQuickViewAltCtas from './components/store-quick-view-alt-ctas/store-quick-view-alt-ctas';
import StoreQuickViewCtas from './components/store-quick-view-ctas/store-quick-view-ctas';
import StoreQuickViewFeatures from './components/store-quick-view-features/store-quick-view-features';
import StoreQuickViewHeader from './components/store-quick-view-header/store-quick-view-header';
import { MapMarkerAltSolidIcon } from '../../shared/icons/icons';
import PhoneNumber from '../phone-number/phone-number';
import PitPassLogo from '../pitpass-logo/pitpass-logo';
import ProductAvailabilityMessage from '../product-availability-message/product-availability-message';
import ShorterWaitTimeMessage from '../shorter-wait-time-message/shorter-wait-time-message';
import ShowHours from '../show-hours/show-hours';
import StoreAddress from '../store-address/store-address';

import { useOptimisticData } from '../../shared/hooks/hooks';

import { getStockCount } from '../../shared/utils/product/product';

import {
  DtAddressData,
  Maybe,
  StoreLocatorFeatureData,
  StoreReviewRatingData
} from '../../shared/types/graphql-types';
import { GroupEntryStocks, ProductItem } from '../../shared/types/product';
import { ENTRY_TYPES } from '../../shared/constants/cart';

import './store-quick-view.scss';

interface StoreQuickViewProps {
  address?: Maybe<DtAddressData>;
  cartReconciliationWarningMessage?: string;
  className?: string;
  distance?: number;
  features?: Array<StoreLocatorFeatureData>;
  groupEntryStocks?: GroupEntryStocks[];
  hasRatingLink?: boolean;
  hideHeader?: boolean;
  hidePhone?: boolean;
  hideReadReviews?: boolean;
  hideShorterWaitTimeMessage?: boolean;
  isCollapsible?: boolean;
  isMyStore?: boolean;
  isPitPassStore?: boolean;
  isStoreSelectionDisabled?: boolean;
  legacyStoreCode?: string;
  myStoreTitle?: string;
  onCallStore?: () => void;
  onChangeStore?: (changeStore: boolean) => void;
  onContinueClick?: () => void;
  onGetDirectionsClick?: () => void;
  onReadReview?: () => void;
  onReadReviewClick?: () => void;
  onScheduleAppointmentClick?: () => void;
  onSelectStore?: (address: DtAddressData, storeCode: string) => void;
  onSetStoreClick?: (results: object) => void;
  onShareClick?: () => void;
  onShopProductsClick?: () => void;
  onStoreDetailsLinkClick?: () => void;
  productItem?: ProductItem;
  rating?: Maybe<StoreReviewRatingData>;
  selectStoreButtonLabel?: string;
  shouldSetStoreAndCart?: boolean;
  shouldSummarizePackage?: boolean;
  showAltChangeStoreCTA?: boolean;
  showAltDirectionsCTA?: boolean;
  showAltScheduleAppointmentCTA?: boolean;
  showAltShareStoreCTA?: boolean;
  showAppointmentCTA?: boolean;
  showContinueCTA?: boolean;
  showMakeMyStoreCTA?: boolean;
  showShopProductsCTA?: boolean;
  storeCode?: Maybe<string>;
  storeDetailsLink?: string;
  storeHours?: {
    monFriHours: string;
    satHours: string;
    sunHours: string;
  };
}

function StoreQuickView(props: StoreQuickViewProps) {
  const {
    address,
    cartReconciliationWarningMessage,
    className,
    distance,
    features,
    hideHeader,
    hideReadReviews,
    groupEntryStocks = [],
    hasRatingLink,
    hidePhone,
    hideShorterWaitTimeMessage = false,
    isCollapsible = false,
    isMyStore = false,
    isPitPassStore = false,
    isStoreSelectionDisabled,
    legacyStoreCode,
    myStoreTitle,
    onCallStore,
    onChangeStore,
    onContinueClick,
    onGetDirectionsClick,
    onReadReview,
    onReadReviewClick,
    onScheduleAppointmentClick,
    onSelectStore,
    onSetStoreClick,
    onShareClick,
    onShopProductsClick,
    onStoreDetailsLinkClick,
    productItem,
    rating,
    selectStoreButtonLabel,
    shouldSetStoreAndCart,
    shouldSummarizePackage,
    showAltChangeStoreCTA,
    showAltDirectionsCTA,
    showAltScheduleAppointmentCTA,
    showAltShareStoreCTA,
    showAppointmentCTA,
    showContinueCTA,
    showMakeMyStoreCTA,
    showShopProductsCTA,
    storeCode,
    storeDetailsLink,
    storeHours
  } = props;
  const showHeader = !hideHeader;
  const { phone } = address || {};
  const optimisticData = useOptimisticData();
  const showHeaderPitPassLogo = isPitPassStore && isMyStore && isNaN(distance!);
  const showBodyPitPassLogo =
    isPitPassStore && (!isMyStore || !isNaN(distance!));

  function setOptimisticStoreData() {
    optimisticData.write({
      store: {
        address,
        rating
      }
    });
  }

  const { availabilityMessage, availabilityMessageRank, stockLevelStatus } =
    groupEntryStocks?.[0]?.stock?.[0] || {};

  const singlePackageGroupEntryStock =
    groupEntryStocks?.length === 1 &&
    groupEntryStocks[0].groupEntry?.entryType === ENTRY_TYPES.PACKAGE
      ? groupEntryStocks[0]
      : undefined;

  const singleStaggeredGroupEntryStock =
    groupEntryStocks?.length === 1 &&
    groupEntryStocks[0].groupEntry?.entryType === ENTRY_TYPES.STAGGERED
      ? groupEntryStocks[0]
      : undefined;

  return (
    <div className={`store-quick-view ${className}`} styleName="container">
      <div className="store-quick-view__top" styleName="top">
        {isMyStore && <MapMarkerAltSolidIcon styleName="my-store-badge" />}
        <div styleName="store-info">
          {showHeader && (
            <StoreQuickViewHeader
              isMyStore={isMyStore}
              myStoreTitle={myStoreTitle}
              showPitPassLogo={showHeaderPitPassLogo}
            />
          )}
          <div styleName="details-container">
            <div styleName="left-col">
              <StoreAddress
                address={address}
                hasRatingLink={hasRatingLink}
                hidePhone
                onCallStore={onCallStore}
                onRatingClick={() => {
                  setOptimisticStoreData();
                  onReadReviewClick?.();

                  if (onReadReview) {
                    onReadReview();
                  }
                }}
                rating={rating}
                showReviews={!hideReadReviews}
                storeCode={storeCode}
              />
            </div>
            <div styleName="right-col">
              {typeof distance === 'number' && (
                <Typography styleName="store-distance" tag="div" weight="black">
                  {distance.toFixed(1)} mi
                </Typography>
              )}

              {showBodyPitPassLogo && (
                <div styleName="pitpass-logo">
                  <PitPassLogo />
                </div>
              )}

              {groupEntryStocks?.length === 1 && availabilityMessage && (
                <div styleName="availability">
                  <ProductAvailabilityMessage
                    availabilityMessage={availabilityMessage || ''}
                    availabilityMessageRank={availabilityMessageRank || 0}
                    stockLevelCode={stockLevelStatus?.code || ''}
                  />
                </div>
              )}
              {singlePackageGroupEntryStock && !shouldSummarizePackage && (
                <>
                  <div styleName="stock-count">{`Tires: ${getStockCount(
                    singlePackageGroupEntryStock?.stock?.[0]?.stockCount
                  )} in Stock`}</div>
                  <div styleName="stock-count">{`Wheels: ${getStockCount(
                    singlePackageGroupEntryStock?.stock?.[1]?.stockCount
                  )} in Stock`}</div>
                </>
              )}
              {singleStaggeredGroupEntryStock && (
                <>
                  <Typography
                    size="small"
                    styleName="stock-count"
                    tag="div"
                    weight="heavy"
                  >{`Front: ${getStockCount(
                    singleStaggeredGroupEntryStock?.stock?.[0]?.stockCount
                  )} in Stock`}</Typography>
                  <Typography
                    size="small"
                    styleName="stock-count"
                    tag="div"
                    weight="heavy"
                  >{`Rear: ${getStockCount(
                    singleStaggeredGroupEntryStock?.stock?.[1]?.stockCount
                  )} in Stock`}</Typography>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Collapsible
        hideToggle={!isCollapsible}
        isOpenByDefault={!isCollapsible}
        label={
          <PhoneNumber
            className="store-quick-view__phone-collapsible"
            phone={phone}
          />
        }
        toggleType="CHEVRON"
      >
        {!isCollapsible && !hidePhone && (
          <PhoneNumber
            className="store-quick-view__phone-expanded"
            phone={phone}
          />
        )}
        <div className="store-quick-view__hours-details">
          {storeHours && <ShowHours storeHours={storeHours} />}
          {storeDetailsLink && (
            <div styleName="details-link">
              <Link
                onClick={() => {
                  setOptimisticStoreData();
                  onStoreDetailsLinkClick?.();
                }}
                to={storeDetailsLink}
              >
                Store Details
              </Link>
            </div>
          )}
        </div>
        <StoreQuickViewFeatures features={features} />
        {groupEntryStocks?.length > 1 && (
          <ProductAvailabilityCollapsible groupEntryStocks={groupEntryStocks} />
        )}
        {address && storeCode && (
          <StoreQuickViewAltCtas
            address={address}
            distance={distance}
            legacyStoreCode={legacyStoreCode}
            onChangeStore={onChangeStore}
            onGetDirectionsClick={onGetDirectionsClick}
            onScheduleAppointmentClick={onScheduleAppointmentClick}
            onShareClick={onShareClick}
            rating={rating}
            showAltChangeStoreCTA={showAltChangeStoreCTA}
            showAltDirectionsCTA={showAltDirectionsCTA}
            showAltScheduleAppointmentCTA={showAltScheduleAppointmentCTA}
            showAltShareStoreCTA={showAltShareStoreCTA}
            storeCode={storeCode}
          />
        )}
        {isMyStore && !hideShorterWaitTimeMessage && <ShorterWaitTimeMessage />}
      </Collapsible>
      {address && storeCode && (
        <StoreQuickViewCtas
          address={address}
          cartReconciliationWarningMessage={cartReconciliationWarningMessage}
          isStoreSelectionDisabled={isStoreSelectionDisabled}
          onChangeStore={onChangeStore}
          onContinueClick={onContinueClick}
          onScheduleAppointmentClick={onScheduleAppointmentClick}
          onSelectStore={onSelectStore}
          onSetStoreClick={onSetStoreClick}
          onShopProductsClick={onShopProductsClick}
          productItem={productItem}
          selectStoreButtonLabel={selectStoreButtonLabel}
          shouldSetStoreAndCart={shouldSetStoreAndCart}
          showAppointmentCTA={showAppointmentCTA}
          showContinueCTA={showContinueCTA}
          showMakeMyStoreCTA={showMakeMyStoreCTA}
          showShopProductsCTA={showShopProductsCTA}
          storeCode={storeCode}
        />
      )}
    </div>
  );
}

export default StoreQuickView;
